<template>
    <div class="update-details-container">
        <ClassicBreadCrumb :title="$t('module.replacement.title')" />
        <h1>{{ $t("module.replacement.title") }}</h1>
        <div class="update-container" v-if="!loading">
            <div class="left-part-wrapper" v-if="displayLeftPart">
                <h1>{{ $t("module.toReplace.replace") }}</h1>
                <div>
                    <p>{{ $t("module.table.serialModule") }}</p>
                    <BasicInput
                        name="serialModule"
                        class="input"
                        :placeholder="centrale.numprod"
                        :disabled="true"
                    />
                </div>
                <div>
                    <p>{{ $t("module.toReplace.phone") }}</p>
                    <BasicInput
                        name="phone"
                        class="input"
                        :placeholder="centrale.tel"
                        :disabled="true"
                    />
                </div>
                <div>
                    <p>{{ $t("module.toReplace.centrale") }}</p>
                    <BasicInput
                        name="name"
                        class="input"
                        :placeholder="centrale.nom"
                        :disabled="true"
                    />
                </div>
                <BasicButton
                    @click="goToDisplayRightPart()"
                    class="only-on-mobile button-next"
                >
                    {{ $t("button.next") }}
                </BasicButton>
            </div>
            <div class="middle-part-wrapper" v-if="displayMiddlePart">
                <div class="stroke"></div>
                <i class="fas fa-exchange-alt" />
                <div class="stroke"></div>
            </div>
            <div class="right-part-wrapper" v-if="displayRightPart">
                <h1>{{ $t("module.toReplace.title") }}</h1>
                <div class="input-wrapper">
                    <p>{{ $t("module.table.serialModule") }}</p>
                    <BasicInput
                        class="input"
                        name="newSerial"
                        :modelValue="newSerial"
                        :errorContent="errorContent.newSerial"
                        :disabled="goToReplace || finishReplacement"
                        @update:modelValue="newSerial = $event"
                    />
                </div>
                <p v-if="errorContent.newSerial" class="error">
                    {{ errorContent.newSerial }}
                </p>
                <div
                    v-if="goToReplaceButton || finishReplacement"
                    class="input-wrapper"
                >
                    <p>{{ $t("module.replacement.newPhone") }}</p>
                    <BasicInput
                        name="newPhone"
                        class="input"
                        :placeholder="newPhone"
                        :disabled="true"
                    />
                </div>
                <BasicButton
                    class="button-replace"
                    @click="replace"
                    :disabled="!newSerial || disableButton"
                    v-if="!goToReplace && !finishReplacement"
                >
                    {{
                        $t("module.replace").charAt(0).toUpperCase() +
                        $t("module.replace").slice(1)
                    }}
                </BasicButton>
                <div class="infos-wrapper" v-if="goToReplace">
                    <i class="fa fa-exclamation-triangle"></i>
                    <div class="infos">
                        <p>{{ $t("module.error.warning") }}</p>
                        <p>{{ $t("module.error.explication") }}</p>
                        <BasicButton
                            class="button-download"
                            color="blue"
                            :disabled="disableButton"
                            @click="goToReplacement"
                        >
                            {{ $t("module.replacement.download") }}
                        </BasicButton>
                    </div>
                </div>
                <div class="confirmation-wrapper" v-if="finishReplacement">
                    <i class="fa fa-check-circle"></i>
                    <div class="confirmation">
                        <p>
                            {{
                                $t("module.success.title", {
                                    oldName: centrale.numprod,
                                    newName: newSerial,
                                })
                            }}
                        </p>
                        <p>{{ $t("module.success.explication") }}</p>
                        <BasicButton
                            class="button-download"
                            @click="goToFinishReplacement"
                            :disabled="disableButton"
                        >
                            {{ $t("module.success.button") }}
                        </BasicButton>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="loader-wrapper">
            <Loader />
        </div>
    </div>
</template>

<script>
import ClassicBreadCrumb from "@/components/entities/ClassicBreadCrumb.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import BasicInput from "@/components/basic/BasicInput.vue"
import Loader from "@/components/basic/Loader.vue"

// import { next } from "vue-router"
import { useToast } from "vue-toastification"

const toast = useToast()

import {
    getCentraleById,
    verifyNewCentraleSerial,
    verifyClemobilList,
    updateContract,
} from "@/services/intratone/centralUnit"

import { downloadExcel } from "@/helpers/commonFunctions"

export default {
    name: "UpdateDetails",
    components: {
        ClassicBreadCrumb,
        BasicButton,
        BasicInput,
        Loader,
    },
    inject: ["emitter"],
    data() {
        return {
            displayLeftPart: true,
            displayMiddlePart: false,
            displayRightPart: false,
            goToReplace: false,
            goToReplaceButton: false,
            finishReplacement: false,
            disableButton: false,
            centrale: [],
            newSerial: null,
            newPhone: null,
            sign: null,
            errorContent: {
                newSerial: "",
            },
            isFinish: false,
            loading: false,
        }
    },
    computed: {
        centraleId() {
            return this.$route.params.id
        },
    },
    async mounted() {
        this.loading = true

        await this.isMobileDisplay()
        await this.getModuleInfos()

        this.loading = false
    },
    methods: {
        async replace() {
            this.disableButton = true
            this.errorContent.newSerial = ""

            await verifyNewCentraleSerial(this.centraleId, {
                numprod: this.newSerial.trim(),
            })
                .then((response) => {
                    if (response.error && response.form) {
                        if (response.form.numprod) {
                            this.errorContent.newSerial =
                                response.form.numprod.msg
                        }
                    } else if (response.error == 0) {
                        this.newSerial = response.data.new_serial
                        this.newPhone = response.data.new_tel
                        this.sign = response.data.sign

                        if (response.data.has_mobile_keys) {
                            this.goToReplace = true
                            this.goToReplaceButton = true
                        } else {
                            this.finishReplacement = true
                        }
                    }
                })
                .finally(() => {
                    this.disableButton = false
                })
        },

        isMobileDisplay() {
            if (window.innerWidth <= 1024) {
                this.displayMiddlePart = false
                this.displayRightPart = false
            } else {
                this.displayMiddlePart = true
                this.displayRightPart = true
            }
        },

        goToDisplayRightPart() {
            this.displayLeftPart = false
            this.displayMiddlePart = false
            this.displayRightPart = true
        },

        async goToReplacement() {
            this.disableButton = true
            await verifyClemobilList(
                this.centraleId,
                this.$store.getters["auth/getJwt"]
            )
                .then((response) => {
                    downloadExcel("clemobil_list", response)

                    this.goToReplace = false
                    this.finishReplacement = true
                })
                .finally(() => {
                    this.disableButton = false
                })
        },

        async goToFinishReplacement() {
            this.disableButton = true

            await updateContract(
                this.centraleId,
                {
                    numprod: this.newSerial,
                    sign: this.sign,
                },
                this.$store.getters["auth/getJwt"]
            )
                .then((response) => {
                    if (response.error === 0) {
                        this.isFinish = true

                        let urlPath =
                            this.$store.getters[
                                "miscellaneous/getStartStepReplaceModule"
                            ]

                        if (
                            urlPath.includes("/data/centrale/id") &&
                            urlPath.includes("redirect=")
                        ) {
                            urlPath = urlPath.replace(
                                /(redirect=)\d+/,
                                "redirect=3"
                            )
                        }

                        this.$router.push({
                            path: urlPath,
                        })
                    } else if (response.error === 1 && !response.message) {
                        toast.error(this.$t("alerts.errors.2019"))
                    }
                })
                .finally(() => {
                    this.disableButton = false
                })
        },

        async getModuleInfos() {
            await getCentraleById(this.centraleId).then((response) => {
                this.centrale = response.data
            })
        },
    },

    beforeRouteLeave(to, from, next) {
        if (!this.isFinish && (this.goToReplace || this.finishReplacement)) {
            this.openModal("validation", {
                title: "warning",
                question: this.$t("module.error.isNotFinish"),
                validateButton: this.$t("button.leave"),
                valid: () => {
                    next()
                },
            })
        } else {
            next()
        }
    },

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.path !== "/renew") {
                let path = from.path
                let localStorageReplaceModule = localStorage.getItem(
                    "setStartStepReplaceModule"
                )
                if (from.href == null && localStorageReplaceModule != null) {
                    path = localStorageReplaceModule
                }
                vm.$store.commit(
                    "miscellaneous/setStartStepReplaceModule",
                    path
                )
            }
        })
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/details.scss";

.only-on-mobile {
    @media all and (min-width: 1024px) {
        display: none !important;
    }
}

.update-details-container {
    padding: 20px 80px 40px 80px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: 100%;

    @media all and (max-width: 1024px) {
        padding: 2em 1em;
    }

    h1,
    p {
        margin: 0;
    }

    h1 {
        font-family: $font_avenir_black;
        font-size: 30px;
    }

    .update-container {
        padding-top: 2em;
        display: flex;
        justify-content: space-between;
        gap: 4em;

        @media all and (max-width: 1024px) {
            justify-content: start;
        }

        .left-part-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1em;

            @media all and (min-width: 768px) and (max-width: 1024px) {
                width: 75%;
            }

            @media all and (max-width: 768px) {
                width: 100%;
            }

            h1 {
                font-family: $font-avenir-heavy;
                font-size: $semiBig;
            }

            .button-next {
                width: max-content;
            }

            div {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1em;

                .input {
                    min-width: 150px;
                    max-width: 400px;
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                    width: 100%;
                }

                p {
                    width: 100%;
                    text-wrap: nowrap;
                }
            }
        }

        .middle-part-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1em;
            i {
                font-size: $semiBig;
            }

            .stroke {
                height: 100%;
                width: 1px;
                background-color: $blue-neutral;
            }
        }

        .right-part-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1em;

            @media all and (min-width: 768px) and (max-width: 1024px) {
                width: 75%;
            }

            @media all and (max-width: 768px) {
                width: 100%;
            }

            .error {
                color: $tag-red;
                font-family: $font-avenir-medium;
            }

            h1 {
                font-family: $font-avenir-heavy;
                font-size: $semiBig;
            }

            .button-replace {
                width: max-content;
            }

            div {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1em;

                p {
                    width: 30%;
                }

                .input {
                    width: 70%;
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                }

                &.infos-wrapper {
                    display: flex;
                    align-items: start;
                    gap: 0.5em;

                    i {
                        padding-top: 3px;
                        color: $tag-yellow;
                    }

                    .infos {
                        display: flex;
                        align-items: start;
                        flex-direction: column;
                        gap: 0;

                        p {
                            width: 100%;
                            margin: 0;
                            text-wrap: wrap;

                            &:first-child {
                                font-family: $font-avenir-heavy;
                            }
                        }

                        .button-download {
                            margin-top: 1em;
                            width: max-content;
                        }
                    }
                }

                &.confirmation-wrapper {
                    display: flex;
                    align-items: start;
                    gap: 0.5em;

                    i {
                        padding-top: 3px;
                        color: $green-check;
                    }

                    .confirmation {
                        display: flex;
                        align-items: start;
                        flex-direction: column;
                        gap: 0;

                        p {
                            width: 100%;
                            margin: 0;
                            text-wrap: wrap;

                            &:first-child {
                                font-family: $font-avenir-heavy;
                            }
                        }

                        .button-download {
                            margin-top: 1em;
                        }
                    }
                }
            }
        }
    }

    .loader-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
